@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.cellVerticalAlign {
  display: flex;
  align-items: center;
  line-height: 1.1rem;
  overflow: hidden;
  p {
    margin: 0;
  }
}

.header {
  color: get-color('neutral', 1000);
  border-bottom: 1px solid get-color('neutral', 200);
}

.row {
  &:hover {
    background-color: get-color('lkh-color-primary-40');
  }
}
