@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.results {
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;

  .header {
    border-bottom: 1px solid get-color('neutral', '200');
  }
}
