.newsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  padding: 24px 36px;
  max-width: 1132px;
}

.newsContainerLoader {
  padding: 24px 36px;
  max-width: 1132px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1440px) {
  .newsContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .newsContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 920px) {
  .newsContainer {
    grid-template-columns: 1fr;
  }
}
