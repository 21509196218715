@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.productsPage {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #fafafa;

  .mainContent {
    padding-left: 32px;
    padding-right: 104px;
    background: white;
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 32px;
    place-items: center;
  }

  .modal {
    background: get-color('white') !important;
  }

  .mainModal {
    display: flex;
    flex-direction: column;
    padding: 0 8px 8px 8px;

    .modalIcon {
      font-size: 22px;
    }

    .modalLayout {
      display: flex;
      gap: 16px;
    }

    .modalText {
      .modalTitle {
        margin: 0;
      }

      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 32px;
    }

    .modalActions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
    }
  }
}

.documentItem {
  padding: 8px;
  display: flex;
  align-items: center;
  border: 1px solid get-color('neutral', '100');
  border-radius: 2px;
  margin-bottom: 12px;

  &.isDownloaded {
    border: 1px solid get-color('lkh-color-primary-80');
  }

  .icon {
    display: grid;
    place-items: center;
    font-size: 16px;
    width: 56px;
    height: 56px;
    margin-right: 24px;
    background-color: get-color('lkh-color-primary-40');
    border-radius: 2px;
  }

  .title {
    flex: 1;
  }
}

.webPageText {
  a {
    &:link {
      color: get-color('lkh-color-primary-60');
    }

    &:visited {
      color: get-color('lkh-color-primary-60');
    }
  }
}
