/*
NOTE
- if needed styles from ui-lib below that are commented out can be enabled for customization;
- as ref for override of ui-lib styles for tailwind you can check below the styles that are not commented out.
*/

// Tailwind directives for CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

// Responsive spacing
// .p-res {
//   @apply p-[32px] s:p-s m:p-m l:p-l xl:p-xl xxl:p-xxl;
// }

// .m-res {
//   @apply m-[32px] s:m-s m:m-m l:m-l xl:m-xl xxl:m-xxl;
// }

.layout-res {
  @apply mx-[24px] l:mx-xxl;
  @apply bg-white-100;
  foo: bar;
}

// ------------------------------------------------------------------------
// Grid helpers
.grid-mx-res {
  @apply mx-[15px] s:mx-l;
  foo: bar;
}

// Align grid vertically using same value as for gap
.grid-my-res {
  @apply my-m l:my-l;
  foo: bar;
}

.grid-gap-res {
  @apply gap-[24px] s:gap-s m:gap-m l:gap-l xl:gap-xl xxl:gap-xxl;
}
// ------------------------------------------------------------------------

// Responsive grid
.grid-res {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid;
}

.grid-res-1 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1;
}

.grid-res-2 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-2;
}

.grid-res-3 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-3;
}

.grid-res-4 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-4;
}

.grid-res-5 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-5;
}

.grid-res-6 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-6;
}

.grid-res-7 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-7;
}

.grid-res-8 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-8;
}

.grid-res-9 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-9;
}

.grid-res-10 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-10;
}

.grid-res-11 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-11;
}

.grid-res-12 {
  @extend .grid-mx-res;
  @extend .grid-my-res;
  @extend .grid-gap-res;
  @apply grid grid-cols-1 s:grid-cols-12;
}

// Components
.field-res {
  @apply flex flex-wrap items-stretch;
  foo: bar;
}

.separator-res {
  @apply my-[30px];
  foo: bar;
}

.person-data-offer-res {
  @apply mx-l;
  foo: bar;
}
