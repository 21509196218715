.section {
  margin-top: 200px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  svg {
    width: 64px !important;
    height: 64px !important;
  }
}
