@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.slideContainer {
  $lineColor: get-color('neutral-100');
  $lineHeight: 4px;
  $bulletColor: get-color('lkh-color-primary-80');
  $bulletSize: 16px; // figma says 12px but it's too small
  $legendColorDark: get-color('neutral-800');
  $legendColorLight: get-color('neutral-100');

  position: relative;
  width: 100%;
  padding-top: 10px;

  @mixin sliderThumb() {
    cursor: pointer;
    width: $bulletSize;
    height: $bulletSize;
    background: $bulletColor;
    border: 2px solid get-color('white');
    box-shadow: 0px 2px 8px rgba(17, 12, 38, 0.2);
    border-radius: 50%;
    margin: 0;
  }

  @mixin ruleLine($color) {
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: 8px;
      top: 0;
      left: 50%;
      background-color: $color;
    }
  }

  .slider {
    appearance: none;
    width: 100%;
    background: $lineColor;
    height: $lineHeight;
    outline: none;

    margin: 0;
    position: relative;
  }

  .slider::-webkit-slider-thumb {
    @include sliderThumb();
    appearance: none;
  }

  .slider::-moz-range-thumb {
    @include sliderThumb();
  }

  .labelsList {
    position: relative;
    margin: 0;
    padding: 0;
    width: calc(100% - #{$bulletSize});

    .label {
      position: absolute;
      list-style-type: none;
      width: $bulletSize;
      text-align: center;
      padding-top: 12px;
      margin-top: 4px;
      display: inline-block;
      font-weight: 400;
      font-size: 10px;

      &:before {
        position: absolute;
        display: block;
        content: '';
        background-color: $legendColorDark;
        width: 1px;
        height: 8px;
        top: 0;
        left: calc(50% - 0.5px);
      }
    }

    &.showOdd {
      li:nth-child(odd) {
        @include ruleLine($legendColorDark);
      }

      li:nth-child(even) {
        @include ruleLine($legendColorLight);

        &.label {
          visibility: transparent;
        }
      }
    }

    &.showEven {
      li:nth-child(even) {
        @include ruleLine($legendColorDark);
      }

      li:nth-child(odd) {
        @include ruleLine($legendColorLight);

        &.label {
          color: transparent;
        }
      }
    }
  }

  .helper {
    max-width: 110px;
    margin-bottom: 20px;
  }
}
