@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.tabGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid get-color('neutral', '100');
}

.secondLevelTabs {
  width: 50%;
  @media screen and (max-width: 1436px) {
    width: 100%;
  }
}

.refreshButton {
  position: relative;
  top: -2px;
}

.status {
  margin: 0;
  padding: 0 12px;
  display: flex;
  line-height: 2rem;
  background-color: get-color('background');
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  width: fit-content;

  .iconActive,
  .iconPending,
  .iconHistoric,
  .iconCancelled,
  .iconInprocess,
  .iconReactivated,
  .iconRevisionInProcess,
  .iconUnknownStatus {
    margin-right: 8px;
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .iconActive {
    background-color: get-color('success', '500');
  }

  .iconPending {
    background-color: get-color('warning', '100');
  }

  .iconHistoric {
    background-color: get-color('neutral', '600');
  }

  .iconReactivated {
    background-color: get-color('secondary', '200');
  }

  .iconCancelled {
    background-color: get-color('error', '700');
  }

  .iconInprocess {
    background-color: get-color('neutral', '400');
  }
  .iconRevisionInProcess {
    background-color: get-color('warning', '700');
  }

  .iconUnknownStatus {
    background-color: get-color('neutral-1000');
  }
}

.currencyCell {
  text-align: right;
}

.userCell {
  display: flex;
  align-items: center;
  span {
    padding: 8px;
    background-color: #fafafa;
    border-radius: 50%;
    margin-right: 8px;
  }
}

.hasReminder {
  color: get-color('error', '500');
  span {
    background-color: get-color('error', '50');
  }
}

.center {
  p {
    margin: 0;
  }
  display: flex;
  align-items: center;
}

.right {
  p {
    margin: 0;
  }
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

:global {
  .ag-action-button {
    display: none;
  }
  .ag-row-hover {
    .ag-action-button {
      display: inherit;
    }
  }
}
.header {
  border-bottom: 1px solid get-color('neutral', '200');
}
