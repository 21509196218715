@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.optionLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;

  & > * {
    &:nth-child(1) {
      flex: 0 auto auto;
    }

    &:nth-child(2) {
      flex: 0 auto auto;
    }

    &:nth-child(3) {
      flex: 1;
    }
  }
}

.chipWrap {
  position: relative;
  @include font-sans();
  display: inline-block;
  font-weight: normal;
  line-height: 18px;
  overflow: hidden;
  padding: 4px 10px;
  font-size: 12px;
  margin-right: 16px;
  color: get-color('neutral-1000');
}

.chipWrap::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.chip {
  position: relative;
  z-index: 1;
}
