.card {
  border: 1px solid var(--lkh-text-20, #e6e6e6);
  border-radius: 8px;
  background: var(--lkh-white-100, #fff);
  min-width: 245px;
  width: 90%;
  min-height: 370px;
  height: 370px;
  transition: 0.5s;

  svg {
    color: #10c663;
  }
}

.card:hover {
  box-shadow: 16px 24px 32px 0px #1c1b1f29;
  border-color: #39393b;
}

.cardContent {
  padding: 1em;
}

.link {
  padding: 1em;
  padding-top: 0;
}

.createdAt {
  color: var(--lkh-text-60, #69696e);
  font-size: '12px' !important;
}

.headline {
  color: var(--lkh-title-80, #194038);
  font-size: '16px';
  font-weight: 'bold';
}

.content {
  width: 90%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 56px;
}

@media screen and (max-width: 920px) {
  .card {
    max-width: 320px;
    margin: 0 auto;
  }
}
