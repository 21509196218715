.container {
  button {
    align-self: flex-end;
  }

  .removeFilters {
    align-self: flex-start;
    margin-bottom: 1em;
  }

  .showMoreButton {
    color: var(--lkh-primary-80, #10c663);
    text-decoration-line: underline;
    cursor: pointer;
    font-weight: 400;
    display: inline-block;
  }

  .filterGroup {
    margin-bottom: 16px;
    max-width: 100%;

    .specialSearch,
    .insuredPersonSearch,
    .contractSearch {
      display: grid;
      grid-template-columns: repeat(5, minmax(200px, 1fr));
      > div {
        max-width: 100%;
      }
      grid-gap: 25px 20px;
      label {
        font-size: 16px;
        color: #a0a0a0;
        max-width: 90%;
      }
    }

    @media screen and (max-width: 1436px) {
      .specialSearch,
      .insuredPersonSearch,
      .contractSearch {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
      }
    }

    @media screen and (max-width: 1095px) {
      .specialSearch,
      .insuredPersonSearch,
      .contractSearch {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }

    .button {
      border: none;
      outline-color: #d6d6d6;
    }
    .dropdown {
      border: none;
      background-color: transparent;
      border-radius: 4px;
      padding: 0;
      color: white;
    }

    .input {
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      input {
        border: none;
        background-color: transparent;
        padding: 10px 12px;
      }
    }

    .filterColumn {
      min-width: 180px;
      max-width: 1fr;
      > * {
        width: 100%;
        margin-bottom: 1.2em;
      }
    }

    .filterColumn:last-child {
      margin-right: 0;
    }

    .filterItem,
    .rowItems {
      display: flex;
      justify-content: stretch;
      gap: 10px;
      flex: 1 auto;
    }
    .filterItem {
      flex-direction: column;
    }
    .rowItems {
      flex-direction: row;
      .dropdownButton {
        max-height: 36px;
      }
    }
  }
  .filter {
    box-sizing: border-box;
    margin-right: 10px;
    display: flex;
    align-items: baseline;
    border-bottom: 3px solid transparent;

    .counter {
      padding: 8px;
      margin-right: 10px;
      background-color: white;
      color: #313635;
      font-size: 14px;
      line-height: 16px;
    }
    .label {
      color: #313635;
    }

    &.selectedFilter {
      border-bottom: 3px solid green;
      .counter {
        background-color: green !important;
        color: white !important;
      }
    }
  }
}
