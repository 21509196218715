@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.stepPickerWrapper {
  .step {
    border-bottom: 1px solid get-color('neutral-100');
    padding-bottom: 16px;
    margin-top: 16px;

    .heading {
      justify-content: space-between;
      display: flex;
      align-items: center;
      background: none;
      border-radius: 4px;
      padding-left: 20px;
      border: 1px solid transparent;
      transition: all 0.2s ease-out;
    }

    &.enabled {
      .heading {
        background: get-color('lkh-color-primary-40');
        border-color: get-color('lkh-color-primary-80');
      }
    }
  }

  .toggleBtn {
    background: transparent;
    border: none;
    outline: none;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}
