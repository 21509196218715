.content {
  margin: 0 auto;
  padding: 0px 36px;
  display: grid;
  grid-template-columns: 178px 3fr 1fr;
  grid-gap: 40px;

  a {
    color: #10c663;
  }
}

.markupContent {
  a {
    color: #10c663;
    text-decoration: underline;
  }
}

.label {
  color: #6e6e6e;
}

.subheadline {
  color: #3b3b3b;
}

.articleContent {
  color: #3b3b3b;
}

.backButton {
  margin-top: 1em;
}

@media screen and (max-width: 1200px) {
  .content {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .firstCol {
    > * {
      display: block;
    }

    button {
      margin-bottom: 1em;
    }
  }
}
