.table-override .ag-row-hover {
  --ag-row-hover-color: #f0f0f0;
  background-color: transparent !important;
}

.ag-row-focus {
  background-color: #f0f0f0 !important;
}

.ag-theme-alpine {
  --ag-selected-row-background-color: #f0f0f0 !important;
  --ag-header-background-color: #fff !important;
  --ag-row-hover-color: #f0f0f0 !important;
  --ag-borders: none !important;
  --ag-alpine-active-color: #f0f0f0 !important;
}

.ag-overlay {
  background-color: rgba(255, 255, 255, 0.5);
}
