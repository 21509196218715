@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.errorMessage {
  @include small();
  display: block;
  width: 100%;
  margin-top: 5px;
  padding-left: 16px;
  color: get-color('error', 700);
}
