@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.labelCell {
  color: get-color('white');
  background-color: get-color('secondary', 600);
  border-radius: 4px;
}

.whiteTextOverride {
  color: get-color('white') !important;
}

.tableOverride {
  --ag-row-hover-color: #f0f0f0 !important;
  width: 100%;
}

.cell {
  display: flex;
  align-items: center;
}

.finalPrice {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.finalPriceHeader {
  color: get-color('neutral', 1000);
  border-bottom: 1px solid get-color('neutral', 200);
}
