@import '../node_modules/react-toastify/dist/ReactToastify.css';
@import '../node_modules/lkh-portal-ui-library/dist/lkh-portal-ui-library.css';
@import '../node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';
@import './tailwind';
@font-face {
  font-family: 'Roboto-Light';
  src: url('../public/font/Roboto-Light.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  white-space: pre-line;
}

:root {
  --toastify-color-success: #{get-color('success', '500')};
  --toastify-color-warning: #{get-color('warning', '500')};
  --toastify-color-info: #39393b;
  --toastify-color-error: #{get-color('error', '500')};

  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 800px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}
