@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.portalLayout {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'navigationBar content';

  .content {
    grid-area: content;
  }

  .main {
    // substracts are for height of the header & footer
    min-height: calc(100vh - 96px);
  }

  @include media-screen-down(md) {
    grid-template-areas:
      'navigationBar'
      'content';
    grid-template-rows: auto;
  }
}
