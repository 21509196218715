.cookieOverlay {
  z-index: 9999 !important;
}

.cookieContainer {
  width: clamp(500px, 800px, 60%) !important;
  margin: 0 auto !important;
  display: block !important;
  position: relative !important;
  top: clamp(20px, 30%, 200px) !important;
  background: white !important;
}
