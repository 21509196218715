@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.cell {
  display: flex;
  align-items: center;
  line-height: 1.1rem;
  overflow: hidden;
}

.cellEditor {
  background-color: unset !important;
}

.header {
  border-bottom: 1px solid get-color('neutral', 200);
  overflow-wrap: break-word;
  overflow: hidden;
}

.row {
  &:hover {
    background-color: get-color('lkh-color-primary-40');

    & > .action {
      display: visible;
    }
  }

  &:hover .action {
    opacity: 1;
  }

  .action {
    opacity: 0;
    transition: all 0.3s ease-out;

    .removeButton {
      color: get-color('error', 500);
    }
  }
}
