.contractPage {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #fafafa;
  .mainContent {
    background: white;
    margin-bottom: 60px;
    max-width: 100%;
  }
}
