@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.wizardProgress {
  @mixin adjustStepColors($bg, $text) {
    background: $bg !important;
    color: $text !important;
  }

  @mixin adjustIconColors($fg, $bg, $border) {
    .icon {
      background: $bg;
      color: $fg;
      border-color: $border;
    }
  }

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > *:not(:last-of-type) {
    margin-right: 20px;
  }

  .step {
    display: flex;
    align-items: center;

    padding-left: 6px !important;
    cursor: pointer;

    &:before {
      background: none !important;
    }

    p {
      display: inline;
      margin: 0;
    }

    .icon {
      font-size: 1em;
      border: 1px solid;
      border-radius: 50%;
    }

    &.defaultStep {
      cursor: pointer;
      $background: get-color('white');
      $color: get-color('neutral', 800);
      @include adjustStepColors($background, $color);
      @include adjustIconColors($background, $color, $color);
    }

    &.completeStep {
      $background: get-color('white');
      $color: get-color('secondary', 600);
      @include adjustStepColors($background, $color);
      @include adjustIconColors($color, $background, $color);
    }

    &.activeStep {
      $background: get-color('lkh-color-tertiary-navigation-80');
      $color: get-color('white');
      @include adjustStepColors($background, $color);
      @include adjustIconColors($color, $background, $background);
    }

    &.errorStep {
      $color: get-color('error', 500);
      $background: get-color('white');
      border: 1px solid $color;
      @include adjustStepColors($background, $color);
      @include adjustIconColors($background, $color, $color);

      &.activeStep {
        $background: get-color('error', 500);
        $color: get-color('white');
        border: 1px solid $color;
        @include adjustStepColors($background, $color);
        @include adjustIconColors($background, $color, $color);
      }
    }
  }
}
