@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.container {
  display: flex;
  border-radius: 2px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid get-color('neutral', '100');
  cursor: pointer;

  &:hover {
    background-color: get-color('background');
  }

  .left {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
      border-right: 1px solid get-color('neutral', '100');
    }
    & > * {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    overflow-y: auto;

    .contractChip,
    .personChip {
      display: flex;
      align-items: center;
      p {
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: auto;
      }
    }

    .contractChip {
      background-color: get-color('secondary', '600');
      color: get-color('white') !important;
    }

    .personChip {
      background-color: get-color('background');
      color: get-color('lkh-color-primary-80') !important;
    }

    @include media-screen-down(xl) {
      .contractChip,
      .personChip {
        span {
          margin-right: 0;
        }
        p {
          width: 0;
          height: 0;
          visibility: hidden;
        }
      }
    }
  }
}
