@import 'node_modules/lkh-portal-ui-library/dist/styles/scss/shared.scss';

.card {
  max-width: 340px;
  max-height: 480px;
  border-radius: 4px;
  border: 1px solid get-color('neutral', '100');
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-bottom: 2em;

  .cardCover {
    width: 100%;
    height: 100%;
  }

  .cardContent {
    .selectProduct {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
    }
  }

  .imageContainer {
    width: 324px;
    height: 240px;
    border-radius: 4px;
    margin-bottom: 16px;
    display: inline-block;
    overflow: hidden;
  }

  &:hover {
    box-shadow: 16px 24px 32px 0px #1c1b1f29;
    .imageContainer .cardCover {
      transition: 0.5s;
      transform: scale(1.1);
    }
  }
}
