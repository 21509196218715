.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(144px, 1fr));
  gap: 20px;
}
